<template>
	<v-container>
		<v-sheet rounded="lg" outlined>
			<v-toolbar dense flat>
				<v-toolbar-title>
					App: {{ webAppStore.webApp.id }}
				</v-toolbar-title>
				<v-spacer />
				<v-icon @click="$router.go(-1)">mdi-close</v-icon>
				<template #extension>
					<v-tabs v-model="tab">
						<v-tab>Resources</v-tab>
						<v-tab>Deployments</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>
			<v-divider />
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<web-app-resources />
				</v-tab-item>
			</v-tabs-items>
		</v-sheet>
	</v-container>
</template>

<script>
	import { useWebAppStore } from "@/store/webapps";
	import WebAppResources from "@/views/webapp/WebAppResources";
	export default {
		name: "WebApp",
		components: { WebAppResources },
		data() {
			return {
				tab: 0,
			};
		},
		setup() {
			const webAppStore = useWebAppStore();
			return { webAppStore };
		},
		created() {
			// watch the params of the route to fetch the data again
			this.$watch(
				() => this.$route.params,
				(_new, _old) => {
					if (
						_new &&
						_new.id &&
						_new != _old &&
						this.$route.name === "WebApp"
					) {
						this.webAppStore.fetchWebApp(_new.id).then(() => {
							this.webAppStore.fetchWebAppResources();
						});
					}
				},
				// fetch the data when the view is created and the data is
				// already being observed
				{ immediate: true }
			);
		},
	};
</script>

<style>
</style>